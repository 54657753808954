import axios from "axios";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1/proposal`,
});

const createProposal = async (data) => {
  try {
    const response = await instance.request({
      url: "/create",
      method: "post",
      data,
    });

    return response.data;
  } catch (err) {
    return {
      success: false,
      message: err.message || "An error occured while creating proposal",
    };
  }
};

const getProposals = async (data) => {
  try {
    const response = await instance.request({
      url: "/get",
      method: "get",
      params: data,
    });

    return response.data;
  } catch (err) {
    return {
      success: false,
      message: err.message || "An error occured while fetching proposal",
    };
  }
};

const voteProposal = async (data) => {
  try {
    const response = await instance.request({
      url: "/vote",
      method: "put",
      data,
    });

    return response.data;
  } catch (err) {
    return {
      success: false,
      message: err.message || "An error occured while voting proposal",
    };
  }
};

const concludeProposals = async (data) => {
  try {
    const response = await instance.request({
      url: "/conclude",
      method: "post",
      data,
    });

    return response.data;
  } catch (err) {
    return {
      success: false,
      message: err.message || "An error occured while concluding proposal",
    };
  }
};

export { createProposal, getProposals, voteProposal, concludeProposals };
