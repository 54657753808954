import React from "react";
import "./App.css";
// import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";

class App extends React.Component {
  render() {
    return (
      // <Routes>
      //   <Route path="/" element={<Home />} />
      //   <Route path="/test" element={<div>test</div>} />
      // </Routes>
      <Home />
    );
  }
}

export default App;
