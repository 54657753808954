import React, { useState, useEffect } from "react";
import { Button, Progress, Tag } from "antd";

import { constants } from "../constants";
import { shouldPass } from "../util";

import "./style/style.css";
import { Icon } from "@iconify/react";
import { getProposals } from "../functions/api";
import { BaseLoader } from "./BaseLoader";
import { web3Instance } from "../web3";

const bs = constants.ballotState;

const VotingBallots = ({
  item,
  // ballotDetails,
  // authorityName,
  // newMemberAddress,
  // oldMemberAddress,
  // setTopic = shouldPass(),
  // onClickDetail = shouldPass(),
  // onClickVote = shouldPass(),
  // setDescription = shouldPass(),
  // onClickUpdateProposal = shouldPass(),
  proposalItemHandler,
}) => {
  // console.log("______check", parseInt(item.state));
  const currentTimeInMs = new Date().getTime();
  console.log("testCheck", item.end_time > currentTimeInMs);
  return (
    <div
      // className={
      //   "ballotDiv " + constants.ballotTypesArr[parseInt(item.ballotType)]
      // }
      className={"ballotDiv"}
      id={item._id}
      // ref={(ref) => ballotDetails.set(item._id, ref)}
    >
      <div className="ballotInfoDiv flex">
        <div className="infoLeft flex-full">
          <p
            // onClick={() =>
            //   proposalItemHandler({
            //     title: setTopic(
            //       item.ballotType,
            //       newMemberAddress,
            //       oldMemberAddress
            //     ),
            //   })
            // }
            onClick={() => proposalItemHandler(item)}
            className="topic text-exlarge text-bold"
          >
            {/* {setTopic(item.ballotType, newMemberAddress, oldMemberAddress)} */}
            {item.title}
          </p>
          {/* <p className="company text-small">{authorityName}</p> */}
          <p className="company text-small">CoonUp DAO, Inc.</p>
          {/* <p className="addr text-small">Proposal Address: {item.creator}</p> */}
        </div>
        <div className="infoRight flex-column">
          {/* {bs.InProgress !== item.state && (
          <Button
            className="btn-img text-exlarge"
            type="primary"
            id="ballotDetailBtn"
            onClick={(e) => onClickDetail(e, item.id)}
            icon="down"
          />
        )} */}
          {/* <p className="status text-small">
          Status : {constants.ballotStateArr[parseInt(item.state)]}
        </p> */}
          <Tag
            color={
              item.status === "Progress"
                ? item.end_time > currentTimeInMs
                  ? "#1554ad"
                  : "#eb5638"
                : item.status === "Passed"
                ? "#3c8618"
                : "#a61d24"
            }
          >
            {!(
              item.status === "Progress" && item.end_time > currentTimeInMs
            ) && (
              <i className="flex">
                {item.status === "Passed" ? (
                  <Icon icon="mdi:success" />
                ) : item.status === "Declined" ? (
                  <Icon icon="mdi:cancel" />
                ) : (
                  <Icon icon="streamline:interface-page-controller-loading-half-progress-loading-load-half-wait-waiting" />
                )}
              </i>
            )}
            {/* <p>{constants.ballotStateArr[parseInt(item.state)]}</p> */}
            <p>
              {item.status === "Progress"
                ? item.end_time > currentTimeInMs
                  ? "In progress"
                  : "Waiting"
                : item.status}
            </p>
          </Tag>
        </div>
      </div>
      {/* <div className="ballotContentDiv">
      <div className="voteDiv">
        <div className="imageContent flex">
          <Button
            disabled={![bs.Ready, bs.InProgress].includes(item.state)}
            className="text-large"
            id="yesVotingBtn"
            onClick={() => onClickVote("Y", item.id, item.endTime, this.state)}
          >
            Yes
          </Button>
          <div className="chart flex-column flex-full">
            <div className="number flex">
              <span className="text-bold">
                {item.powerOfAccepts === 0 ? "0" : item.powerOfAccepts}%
              </span>
              <span className="text-bold">
                {item.powerOfRejects === 0 ? "0" : item.powerOfRejects}%
              </span>
            </div>
            <Progress
              percent={item.powerOfAccepts}
              status="active"
              showInfo={false}
            />
          </div>
          <Button
            disabled={![bs.Ready, bs.InProgress].includes(item.state)}
            className="text-large"
            id="noVotingBtn"
            onClick={() => onClickVote("N", item.id, item.endTime, this.state)}
          >
            No
          </Button>
        </div>
        <div className="textContent flex">
          {setDescription(
            item.ballotType,
            newMemberAddress,
            oldMemberAddress,
            item.id
          )}
          <div className="duration">
            {item.state !== bs.Ready && (
              <div>
                <div className="flex">
                  <span>Start : </span>
                  <span>{item.startTime}</span>
                </div>
                <div className="flex">
                  <span>End : </span>
                  <span>{item.endTimeConverted}</span>
                </div>
              </div>
            )}
            {item.state === bs.Ready && (
              <div>
                <div className="flex">
                  <span>duration</span>
                  <span className="days">
                    {item.duration === 0 ? 1 : item.duration}days
                  </span>
                </div>
                <Button
                  className="btn-fill-gray"
                  type="primary"
                  onClick={() =>
                    onClickUpdateProposal(
                      "change",
                      item.id,
                      item.duration,
                      newMemberAddress,
                      oldMemberAddress
                    )
                  }
                >
                  Change
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="memoDiv">
        <p className="text-bold">MEMO</p>
        <p className="text-container">{item.memo}</p>
      </div>
      {item.state === "1" && (
        <div className="revokeDiv flex">
          <Button
            className="btn-fill-gray"
            onClick={() =>
              onClickUpdateProposal(
                "revoke",
                item.id,
                item.duration,
                newMemberAddress,
                oldMemberAddress
              )
            }
          >
            Revoke
          </Button>
        </div>
      )}
    </div> */}
    </div>
  );
};

const NotFoundConatianer = () => (
  <div className="not-found-container">
    <img src="android-chrome-512x512.png" />
    <h1>No nfts found</h1>
  </div>
);

const ShowBallots = ({
  // titles,
  // visibleActiveItems,
  // visibleProposalItems,
  // totalProposalItemLength,
  // visibleFinalizedItems,
  // totalFinalizedItemLength,
  // netName,
  // onClickReadMore = shouldPass(),
  position,
  proposalItemHandler,
}) => {
  // const activeCondition = visibleActiveItems.length > 0;
  // const proposalCondition = visibleProposalItems.length > 0;
  // const finalizedCondition = visibleFinalizedItems.length > 0;
  console.log("____position", position, position === "active");

  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchProposals = async (value) => {
    const { init = true } = value || {};
    try {
      if (init) setLoading(true);
      const resp = await getProposals({
        tab: position,
        ...(!init
          ? { numberOfProposals: 5, prevNumberOfProposals: items.length }
          : {}),
      });
      if (
        resp.payload &&
        resp.payload.proposals &&
        resp.payload.proposals.length > 0
      ) {
        if (init) {
          setItems(() => resp.payload.proposals);
          setCount(() => resp.payload.count);
        } else {
          setItems((prev) => [...prev, ...resp.payload.proposals]);
        }
      }
      if (init) setLoading(false);
      // setItems([{ status: "check" }]);
    } catch (err) {
      setItems(() => []);
      setCount(() => 0);
      if (init) setLoading(false);
      alert(err.message);
    }
  };

  useEffect(() => {
    setItems(() => []);
    setCount(() => 0);
    fetchProposals();
  }, [position]);

  const readMore = () => {
    fetchProposals({ init: false });
  };

  // const activeItems = items.filter(({ status }) => status === "Progress");
  // const proposalItems = items.filter(({ status }) => status === "Pata Nai");
  // const finalizedItems = items.filter(
  //   ({ status }) => status === "Declined" || status === "Passed"
  // );
  console.log("_______check", items);
  console.log("____position", position, position === "active");

  return (
    // <div className="contentDiv container">
    //   {/* <p
    //   className="stateTitle text-heavy"
    //   ref={(ref) => {
    //     titles.activeTitle = ref;
    //   }}
    // >
    //   Active
    // </p> */}
    //   {position === "active" &&
    //     // (activeCondition ? visibleActiveItems : <NotFoundConatianer />)}
    //     (activeCondition ? (
    //       <div>{visibleActiveItems}</div>
    //     ) : (
    //       <NotFoundConatianer />
    //     ))}
    //   {/* <p
    //   className="stateTitle text-heavy"
    //   ref={(ref) => {
    //     titles.proposalTitle = ref;
    //   }}
    // >
    //   Proposals
    // </p> */}
    //   {position === "proposal" &&
    //     (proposalCondition ? (
    //       <div>
    //         {visibleProposalItems}
    //         {visibleProposalItems.length > 0 &&
    //           visibleProposalItems.length < totalProposalItemLength && (
    //             <div className="moreDiv flex flex-center-vertical">
    //               <Button
    //                 className={"flex flex-center-horizontal " + netName}
    //                 onClick={(e) => onClickReadMore("proposal")}
    //               >
    //                 <span>+</span>
    //                 &nbsp;
    //                 <span className="text_btn">Read More</span>
    //               </Button>
    //             </div>
    //           )}
    //       </div>
    //     ) : (
    //       <NotFoundConatianer />
    //     ))}
    //   {/* <p
    //   className="stateTitle text-heavy"
    //   ref={(ref) => {
    //     titles.finalizedTitle = ref;
    //   }}
    // >
    //   Finalized
    // </p> */}
    //   {position === "finalized" &&
    //     (finalizedCondition ? (
    //       <div>
    //         {visibleFinalizedItems}
    //         {visibleFinalizedItems.length > 0 &&
    //           visibleFinalizedItems.length < totalFinalizedItemLength && (
    //             <div className="moreDiv flex flex-center-vertical">
    //               <Button
    //                 className={"flex flex-center-horizontal " + netName}
    //                 onClick={(e) => onClickReadMore("finalized")}
    //               >
    //                 <span>+</span>
    //                 &nbsp;
    //                 <span className="text_btn">Read More</span>
    //               </Button>
    //             </div>
    //           )}
    //       </div>
    //     ) : (
    //       <NotFoundConatianer />
    //     ))}
    // </div>

    <div className="contentDiv container">
      {loading ? (
        <BaseLoader />
      ) : (
        //     <div>
        //       {/* <p
        //   className="stateTitle text-heavy"
        //   ref={(ref) => {
        //     titles.activeTitle = ref;
        //   }}
        //   >
        //   Active
        // </p> */}
        //       {position === "active" &&
        //         // (activeCondition ? visibleActiveItems : <NotFoundConatianer />)}
        //         (items.length > 0 ? (
        //           <div>
        //             {items.map((item, value) => (
        //               <VotingBallots
        //                 key={value}
        //                 item={item}
        //                 // ballotDetails={this.ballotDetails}
        //                 // authorityName={this.data.authorityNames.get(item.creator)}
        //                 // newMemberAddress={newMemberAddress}
        //                 // oldMemberAddress={oldMemberAddress}
        //                 // setTopic={this.setTopic}
        //                 // onClickDetail={this.onClickDetail}
        //                 // onClickVote={this.onClickVote}
        //                 // setDescription={this.setDescription}
        //                 // onClickUpdateProposal={this.onClickUpdateProposal}
        //                 proposalItemHandler={proposalItemHandler}
        //               />
        //             ))}
        //             {count > items.length && (
        //                 <div className="moreDiv flex flex-center-vertical">
        //                   <Button
        //                     className={"flex flex-center-horizontal " + netName}
        //                     onClick={(e) => onClickReadMore("proposal")}
        //                   >
        //                     <span>+</span>
        //                     &nbsp;
        //                     <span className="text_btn">Read More</span>
        //                   </Button>
        //                 </div>
        //               )}
        //           </div>
        //         ) : (
        //           <NotFoundConatianer />
        //         ))}
        //       {/* <p
        //   className="stateTitle text-heavy"
        //   ref={(ref) => {
        //     titles.proposalTitle = ref;
        //   }}
        // >
        //   Proposals
        // </p> */}
        //       {position === "proposal" &&
        //         (proposalItems.length > 0 ? (
        //           <div>
        //             {proposalItems.map((item, value) => (
        //               <VotingBallots
        //                 key={value}
        //                 item={item}
        //                 // ballotDetails={this.ballotDetails}
        //                 // authorityName={this.data.authorityNames.get(item.creator)}
        //                 // newMemberAddress={newMemberAddress}
        //                 // oldMemberAddress={oldMemberAddress}
        //                 // setTopic={this.setTopic}
        //                 // onClickDetail={this.onClickDetail}
        //                 // onClickVote={this.onClickVote}
        //                 // setDescription={this.setDescription}
        //                 // onClickUpdateProposal={this.onClickUpdateProposal}
        //                 proposalItemHandler={proposalItemHandler}
        //               />
        //             ))}
        //             {/* {visibleProposalItems.length > 0 &&
        //           visibleProposalItems.length < totalProposalItemLength && (
        //             <div className="moreDiv flex flex-center-vertical">
        //               <Button
        //                 className={"flex flex-center-horizontal " + netName}
        //                 onClick={(e) => onClickReadMore("proposal")}
        //               >
        //                 <span>+</span>
        //                 &nbsp;
        //                 <span className="text_btn">Read More</span>
        //               </Button>
        //             </div>
        //           )} */}
        //           </div>
        //         ) : (
        //           <NotFoundConatianer />
        //         ))}
        //       {/* <p
        //   className="stateTitle text-heavy"
        //   ref={(ref) => {
        //     titles.finalizedTitle = ref;
        //   }}
        // >
        //   Finalized
        // </p> */}
        //       {position === "finalized" &&
        //         (finalizedItems.length > 0 ? (
        //           <div>
        //             {finalizedItems.map((item, value) => (
        //               <VotingBallots
        //                 key={value}
        //                 item={item}
        //                 // ballotDetails={this.ballotDetails}
        //                 // authorityName={this.data.authorityNames.get(item.creator)}
        //                 // newMemberAddress={newMemberAddress}
        //                 // oldMemberAddress={oldMemberAddress}
        //                 // setTopic={this.setTopic}
        //                 // onClickDetail={this.onClickDetail}
        //                 // onClickVote={this.onClickVote}
        //                 // setDescription={this.setDescription}
        //                 // onClickUpdateProposal={this.onClickUpdateProposal}
        //                 proposalItemHandler={proposalItemHandler}
        //               />
        //             ))}
        //             {/* {visibleFinalizedItems.length > 0 &&
        //           visibleFinalizedItems.length < totalFinalizedItemLength && (
        //             <div className="moreDiv flex flex-center-vertical">
        //               <Button
        //                 className={"flex flex-center-horizontal " + netName}
        //                 onClick={(e) => onClickReadMore("finalized")}
        //               >
        //                 <span>+</span>
        //                 &nbsp;
        //                 <span className="text_btn">Read More</span>
        //               </Button>
        //             </div>
        //           )} */}
        //           </div>
        //         ) : (
        //           <NotFoundConatianer />
        //         ))}
        //     </div>
        <div>
          {items.length > 0 ? (
            <div>
              {items.map((item, value) => (
                <VotingBallots
                  key={value}
                  item={item}
                  // ballotDetails={this.ballotDetails}
                  // authorityName={this.data.authorityNames.get(item.creator)}
                  // newMemberAddress={newMemberAddress}
                  // oldMemberAddress={oldMemberAddress}
                  // setTopic={this.setTopic}
                  // onClickDetail={this.onClickDetail}
                  // onClickVote={this.onClickVote}
                  // setDescription={this.setDescription}
                  // onClickUpdateProposal={this.onClickUpdateProposal}
                  proposalItemHandler={proposalItemHandler}
                />
              ))}
              {count > items.length && (
                <div className="moreDiv flex flex-center-vertical">
                  <Button
                    className={"flex flex-center-horizontal"}
                    onClick={readMore}
                  >
                    <span>+</span>
                    &nbsp;
                    <span className="text_btn">Read More</span>
                  </Button>
                </div>
              )}
            </div>
          ) : !web3Instance.haveToken ?(
            <NotFoundConatianer />
          )
        :
        <>
        </>}
        </div>
      )}
    </div>
  );
};

export { VotingBallots, ShowBallots };
