import React, { useState } from "react";
import { Button, Row, Menu, Input, Affix } from "antd";
import { Icon } from "@iconify/react";

import "./style/style.css";
import { web3Instance } from "../web3";

const TopNav = ({
  netName,
  nav,
  myBalance,
  myLockedBalance,
  onMenuClick = (f) => f,
  getStakingModal = (f) => f,
  onClickSubMenu,
  position,
}) => {
  const sampleAddress = web3Instance.defaultAccount;
  return (
    <div className="flex header-container">
      <div className="header-logo flex flex-center-horizontal">
        <img src="android-chrome-512x512.png" alt="" width="100px" />
        <span style={{ fontWeight: "800", fontSize: "25px", color: "white" }}>
          Coon Up
        </span>
      </div>
      {/* <div className={'header-menu center-vertical ' + netName}>
      <Menu
        className='flex flex-center-horizontal'
        onClick={onMenuClick}
        selectedKeys={[ nav ]}
        mode={'horizontal'}>
        // to be commented <Menu.Item key='1' className={'text-large text-bold ' + netName}>Authority</Menu.Item>
        <Menu.Item key='2' className={'text-large text-bold ' + netName}>Voting</Menu.Item>
      </Menu>
    </div> */}
      {/* <div className="header-staking flex flex-center-horizontal flex-end-vertical flex-full">
      <div className="flex flex-full flex-column flex-center-vertical">
        <p className={"staked " + netName}>Total {myBalance} $COON</p>
        <p className={"meta " + netName}>(NFT {myLockedBalance} $COON)</p>
      </div>
      <Button
        className={"btn-grid-primary " + netName}
        type="primary"
        onClick={getStakingModal}
      >
        $COON Staking
      </Button>
    </div> */}
      <div className="input-container">
        <Input.Search
          className="flex-full"
          placeholder="Search by Type, Proposal, Keywords"
          // onChange={searchFunction}
          enterButton
        />
      </div>
      <div className="header-right">
        <div>
          <SubNav position={position} onClickSubMenu={onClickSubMenu} />
        </div>
        <div className="wallet-container">
          <p>
            {sampleAddress.slice(0, 4) +
              "..." +
              sampleAddress.slice(
                sampleAddress.length - 4,
                sampleAddress.length
              )}
          </p>
          <Icon icon="teenyicons:down-solid" width="10" />
        </div>
      </div>
      {/* {sampleAddress.length >0 ?<Button className={'btn-grid-primary ' + netName} type='primary' onClick={getStakingModal}>Connect</Button> : <></>} */}
    </div>
  );
};

const SubHeader = ({
  netName,
  placeholder,
  condition = true,
  btnText,
  btnIcon = null,
  loading = false,
  searchFunction = (f) => f,
  btnFunction = (f) => f,
  position,
}) => (
  <div className="sub-header">
    <div className={"functionDiv flex container " + netName}>
      {/* <Input.Search
        className="flex-full"
        placeholder={placeholder}
        onChange={searchFunction}
        enterButton
      /> */}
      <div>
        <h1>
          {position === "active"
            ? "Active"
            : position === "proposal"
            ? "Proposal"
            : position === "waiting"
            ? "Waiting"
            : "Finalized"}
        </h1>
        <p>Active Proposal are shown here</p>
      </div>
      <Button
        className="apply_proposal_Btn flex flex-center-horizontal"
        disabled={!condition}
        onClick={(e) => btnFunction()}
        loading={loading}
      >
        <span>{btnIcon}</span>
        &nbsp;
        <span className="text_btn">{btnText}</span>
      </Button>
    </div>
  </div>
);

const SubNav = ({ position, onClickSubMenu = (f) => f }) => (
  <div className="sub-menu flex flex-center-vertical">
    <Menu onClick={onClickSubMenu} selectedKeys={[position]} mode="horizontal">
      <Menu.Item key="active">Active</Menu.Item>
      <Menu.Item key="waiting">Waiting</Menu.Item>
      <Menu.Item key="proposal">Proposal</Menu.Item>
      <Menu.Item key="finalized">Finalized</Menu.Item>
    </Menu>
  </div>
);

const FootNav = ({ netName, onClickFootIcon }) => (
  <Row className="container">
    <p>
      Copyright © Since 2019 CoonUp DAO Technology, Inc. All rights reserved
    </p>
    <div className="flex flex-full">
      <Button className="btn-img" onClick={onClickFootIcon}>
        <img src="/img/metadium_footer.png" alt="metadium" key="metadium" />
      </Button>
      <Button className="btn-img" onClick={onClickFootIcon}>
        <img
          src={`/img/explorer_footer_${netName}.png`}
          alt="explorer"
          key="explorer"
        />
      </Button>
      <Button className="btn-img" onClick={onClickFootIcon}>
        <img src="/img/github_footer.png" alt="github" key="github" />
      </Button>
    </div>
  </Row>
);

export { TopNav, SubHeader, SubNav, FootNav };
