import React, { useState } from "react";
import { Button, Select, Tag } from "antd";
import moment from "moment";

import { web3Instance } from "../web3";
import { constants } from "../constants";
import * as util from "../util";

import "./style/style.css";
import { Icon } from "@iconify/react";
import { Separator } from "./Separater";
import CountdownTimer from "./CountdownTimer";
import { concludeProposals, voteProposal } from "../functions/api";

const ProposalItem = ({ data, proposalItemHandler }) => {
  const address = web3Instance.defaultAccount;
  const { proposer } = data;

  const [inVoting, setInVoting] = useState(data.in_voting);
  const [status, setStatus] = useState(data.status);
  const [numTokens, setnumTokens] = useState(0);

  console.log("____test", inVoting, data.in_voting);

  const currentTimeInMs = new Date().getTime();

  const isVotingEnds = data.end_time < currentTimeInMs || !inVoting;

  const isAlreadyVoted = data.voters.includes(address.toLowerCase());

  const callVote = async (value) => {
    let check;
    console.log(data.id)
    if (!(isVotingEnds || isAlreadyVoted)) {
      await web3Instance.contract.methods
        .castVote(parseInt(data.id), 0, value === "accept" ? true : false)
        .send({ from: web3Instance.defaultAccount })
        .on("confirmation", async (confirmationNumber) => {
          check++;
          if (check === 1) {
            const resp = await voteProposal({
              type: value,
              user: address,
              proposal_id: data._id,
            });
            if (resp.success && resp.payload && resp.payload.proposal) {
              proposalItemHandler(resp.payload.proposal);
            }
            // Additional actions to perform when transaction is confirmed
            // Example: Update UI, fetch updated data, etc.
          } else {
            console.log("Transaction is still pending...");
          }
        });
    }
  };

  const ownerAddress = process.env.REACT_APP_OWNER_ADDRESS;

  const isOwner = address.toLowerCase() === ownerAddress.toLowerCase();

  const conclude = async () => {
    if (isOwner) {
      const resp = await concludeProposals({
        user: address,
        proposal_id: data._id,
      });
      console.log(resp);
      if (resp.success && resp.payload) {
        setInVoting(resp.payload.in_voting);
        setStatus(resp.payload.status);
      }
    }
  };

  const acceptRatio =
    data.passer + data.decliner > 0
      ? (data.passer * 100) / (data.passer + data.decliner)
      : 0;
  const rejectRatio =
    data.passer + data.decliner > 0
      ? (data.decliner * 100) / (data.passer + data.decliner)
      : 0;

  return (
    <div>
      <div className="contentDiv container">
        <div className="backBtnDiv">
          <Button
            className={
              "flex flex-center-horizontal text-large " + web3Instance.netName
            }
            onClick={() => proposalItemHandler(null)}
            // loading={buttonLoading}
          >
            <span>
              <Icon icon="mingcute:left-line" />
            </span>
            &nbsp;
            <span className="text_btn">Back to Voting</span>
          </Button>
        </div>
        <div className="content-container">
          <div className="first-container">
            <div className="left-container">
              <div>
                <h1>{data.title}</h1>
              </div>
              <div className="tag-container">
                <Tag
                  color={
                    status === "Progress"
                      ? "#1554ad"
                      : status === "Passed"
                      ? "#3c8618"
                      : "#a61d24"
                  }
                >
                  {status !== "Progress" && (
                    <i className="flex">
                      {status === "Passed" ? (
                        <Icon icon="mdi:success" />
                      ) : (
                        <Icon icon="mdi:cancel" />
                      )}
                    </i>
                  )}
                  <p class="mb-0">
                    {status === "Progress" ? "In progress" : status}
                  </p>
                </Tag>
                {/* <p className="mb-0">003</p> */}
              </div>
              <div className="proposal-detail">
                {proposer && (
                  <div>
                    <p>Proposer</p>
                    <p className="mb-0">
                      {proposer.slice(0, 4) +
                        "..." +
                        proposer.slice(proposer.length - 4, proposer.length)}
                    </p>
                  </div>
                )}
                {data.created_at && (
                  <div>
                    <p>Start date</p>
                    <p className="mb-0">
                      {moment(data.created_at).format("DD MMM YYYY, h:mm A")}
                    </p>
                  </div>
                )}
                {data.end_time && (
                  <div>
                    <p>End date</p>
                    <p className="mb-0">
                      {moment(data.end_time).format("DD MMM YYYY, h:mm A")}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {inVoting && (
              <div className="timer-container">
                <CountdownTimer targetDate={data.end_time} />
              </div>
            )}
          </div>
          <Separator />
          <div className="cast-container">
            <div className="left-container">
              {isVotingEnds ? (
                <h2 className="mb-0">Voting Ends!</h2>
              ) : isAlreadyVoted ? (
                <h2 className="mb-0">You have already voted!</h2>
              ) : (
                <>
                  <h2 className="mb-0">Cast Your vote</h2>
                  <p className="mb-0">
                    Use your 740,000 votes to vote for or against this proposal
                  </p>
                </>
              )}
            </div>
            <div className="btn-container">
              {!(isVotingEnds || isAlreadyVoted) && (
                <div className="vote-btns-wrapper">
                  <button
                    className="accept-btn"
                    onClick={() => callVote("accept")}
                  >
                    <i className="flex">
                      <Icon icon="mdi:success" />
                    </i>
                    &nbsp;&nbsp;
                    <p className="mb-0">Accept</p>
                  </button>
                  <button
                    className="reject-btn"
                    onClick={() => callVote("reject")}
                  >
                    <i className="flex">
                      <Icon icon="mdi:cancel" />
                    </i>
                    &nbsp;&nbsp;
                    <p className="mb-0">Reject</p>
                  </button>
                </div>
              )}
              {inVoting && isOwner && (
                <button className="conclude-btn" onClick={conclude}>
                  <p className="mb-0">Conclude</p>
                </button>
              )}
            </div>
          </div>
          <Separator />
          <div className="details-container">
            <div className="left-container">
              <h2>Details</h2>
              <p>{data.description || "-"}</p>
            </div>
            <div>
              <div className="voting-details">
                <div>
                  <h2>Voting</h2>
                </div>
                <div className="progerss-main">
                  <div className="progerss-bar">
                    <div
                      className="accept-value"
                      style={{ width: acceptRatio + "%" }}
                    />
                    <div
                      className="reject-value"
                      style={{ width: rejectRatio + "%" }}
                    />
                  </div>
                </div>
                <div className="bottom-details">
                  <div className="accept">
                    <h3 className="heading">FOR</h3>
                    <p className="percent mb-0">{acceptRatio}%</p>
                    <p className="votes mb-0">
                      {data.passer} {data.passer > 0 ? "votes" : "vote"}
                    </p>
                  </div>
                  <div className="reject">
                    <h3 className="heading">AGAINST</h3>
                    <p className="percent mb-0">{rejectRatio}%</p>
                    <p className="votes mb-0">
                      {data.decliner} {data.decliner > 0 ? "votes" : "vote"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="threshold-details">
                <div>
                  <h3 className="heading">Pass threshold</h3>
                  <h3>{acceptRatio + rejectRatio}% / 50%</h3>
                </div>
                <div>
                  <p className="mb-0">
                    Atleast 50% participation is needed to proceed with this
                    proposal
                  </p>
                </div>
              </div>
              <div>
                <h2>History</h2>
                <div>
                  {data.created_at && (
                    <div className="history-details">
                      <p>
                        <i className="flex">
                          <Icon icon="mdi:success" />
                        </i>
                        <span>Deployed</span>
                      </p>
                      <p>{moment(data.created_at).format("DD MMM YYYY")}</p>
                    </div>
                  )}
                  {/* <div className="history-details">
                    <p>
                      <i className="flex">
                        <Icon icon="mdi:success" />
                      </i>
                      <span>
                        <div>Proposal id</div>
                        <div className="sub-text">
                          by:{" "}
                          {address.slice(0, 4) +
                            "..." +
                            address.slice(address.length - 4, address.length)}
                        </div>
                      </span>
                    </p>
                    <p>
                      <div>14 Nov 2022</div>
                      <div className="sub-text">Generation 1003</div>
                    </p>
                  </div> */}
                  {data.id && (
                    <div className="history-details">
                      <p>
                        <i className="flex">
                          <Icon icon="mdi:success" />
                        </i>
                        <span>Proposal id</span>
                      </p>
                      <p>{data.id}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProposalItem };
