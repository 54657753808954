import React from "react";
import { Layout } from "antd";

import {
  TopNav,
  FootNav,
  StakingModal,
  ErrModal,
  AccessFailedModal,
  Voting,
  Authority,
  BaseLoader,
} from "../../components";
import getWeb3Instance, { web3Instance } from "../../web3";
import { constants } from "../../constants";
import * as util from "../../util";

const { Header, Content, Footer } = Layout;

class Home extends React.Component {

  data = {
    myBalance: 0,
    myLockedBalance: 0,
    stakingTopic: "deposit",
    stakingAmount: "",
    stakingMax: null,
    stakingMin: null,
    eventsWatch: null,
    ballotMemberOriginData: {},
    ballotBasicOriginData: {},
    voteLength: 0,
    authorityOriginData: [],
    errTitle: null,
    errContent: null,
    errLink: null,
    errAccessFail: null,
    isMember: false,
  };

  state = {
    loadWeb3: false,
    accessFailMsg: null,
    nav: "1",
    contractReady: false,
    stakingModalVisible: false,
    errModalVisible: false,
    loading: false,
    showProposal: false,
    proposalItem: null,
    voting:null,
    contract:null,
    position: "active",
  };

  constructor(props) {
    super(props);

    /* Get web3 instance. */
    getWeb3Instance().then(
      async (web3Config) => {
        this.initContracts(web3Config);
        console.log("debugMode: ", constants.debugMode);
        this.setState({ loadWeb3: true });
      },
      async (error) => {
        console.log("getWeb3 error: ", error);
        this.setState({ loadWeb3: false, accessFailMsg: error.message });
      }
    );
  }

  async initContracts(web3Config) { 
    this.state.contract = web3Instance.contract
      window.ethereum.on("accountsChanged", async (chagedAccounts) => {
        await this.updateDefaultAccount(chagedAccounts[0]);
      });
      this.setState({ contractReady: true });
  }

  onMenuClick = ({ key }) => {
    if (this.state.showProposal && this.state.nav === "2" && key === "2") {
      this.convertVotingComponent("voting");
    } else {
      this.setState({ nav: key });
    }
  };

  onClickFootIcon = (e) => {
    switch (e.target.alt) {
      case "hypercoon":
        window.open("https://coonup.io/", "_blank");
        break;
      case "explorer":
        window.open("https://etherscan.io/", "_blank");
        break;
      case "github":
        window.open("https://github.com/CoonUp/", "_blank");
        break;
      default:
    }
  };

  getContent() {
    if (!this.state.loadWeb3) return;
    switch (this.state.nav) {
      case "1":
        return (
          <Voting
            title="Voting"
            contracts={this.state.contract}
            getErrModal={this.getErrModal}
            initContractData={this.initContractData}
            refreshContractData={this.refreshContractData}
            authorityOriginData={this.data.authorityOriginData}
            ballotMemberOriginData={this.data.ballotMemberOriginData}
            ballotBasicOriginData={this.data.ballotBasicOriginData}
            convertVotingComponent={this.convertVotingComponent}
            loading={this.state.loading}
            convertLoading={this.convertLoading}
            showProposal={this.state.showProposal}
            proposalItem={this.state.proposalItem}
            proposalItemHandler={this.proposalItemHandler}
            isMember={this.data.isMember}
            stakingMax={this.data.stakingMax}
            stakingMin={this.data.stakingMin}
            position={this.state.position}
          />
        );
      default:
    }
    this.setState({ selectedMenu: true });
  }

  convertVotingComponent = (component) => {
    switch (component) {
      case "voting":
        this.setState({ showProposal: false });
        break;
      case "proposal":
        this.setState({ showProposal: true });
        break;
      default:
        break;
    }
  };

  convertLoading = (state) => {
    if (typeof state === "boolean") {
      this.setState({ loading: state });
    }
  };

  getErrModal = (
    _err = "Unknown Error",
    _title = "Unknown Error",
    _link = false
  ) => {
    if (_err.includes("error:")) _err = _err.split("error:")[1];

    this.data.errTitle = _title;
    this.data.errContent = _err;
    if (_link)
      this.data.errLink =  _link;
    else this.data.errLink = false;
    this.setState({ errModalVisible: true });
  };

  handleSelectChange = (topic) => {
    this.data.stakingTopic = topic;
    this.setState({});
  };

  handleInputChange = (event) => {
    let value = event.target.value;
    if (/^([0-9]*)$/.test(value)) {
      this.data.stakingAmount = value;
      this.setState({ errStakging: false });
    }
  };

  onClickSubMenu = (e) => {
    // switch (e.key) {
    //   case "active":
    //     if (this.titles.activeTitle)
    //       window.scrollTo(0, this.titles.activeTitle.offsetTop - 70);
    //     break;
    //   case "proposal":
    //     if (this.titles.proposalTitle)
    //       window.scrollTo(0, this.titles.proposalTitle.offsetTop - 70);
    //     break;
    //   case "finalized":
    //     if (this.titles.finalizedTitle)
    //       window.scrollTo(0, this.titles.finalizedTitle.offsetTop - 70);
    //     break;
    //   default:
    //     break;
    // }
    this.setState({ position: e.key });
  };

  proposalItemHandler = (value) => {
    this.setState({ proposalItem: value });
  };

  render() {
    return (
      <Layout className="layout">
        <AccessFailedModal
          visible={!!this.state.accessFailMsg}
          message={this.state.accessFailMsg}
        />

        {this.state.contractReady && this.state.loadWeb3 ? (
          <div className="flex-column">
            <Header className={web3Instance.netName}>
              <TopNav
                netName={web3Instance.netName}
                nav={this.state.nav}
                myBalance={this.data.myBalance}
                myLockedBalance={this.data.myLockedBalance}
                onMenuClick={this.onMenuClick}
                getStakingModal={this.getStakingModal}
                onClickSubMenu={this.onClickSubMenu}
                position={this.state.position}
              />
            </Header>

      

            <ErrModal
              netName={web3Instance.netName}
              title={this.data.errTitle}
              err={this.data.errContent}
              link={this.data.errLink}
              visible={this.state.errModalVisible}
              coloseErrModal={() =>
                this.setState({ errModalVisible: !this.state.loadWeb3 })
              }
            />

            <Content>
              {this.state.loadWeb3 ? (
                <div> {this.getContent()} </div>
              ) : (
                this.getErrModal(
                  "This is an unknown network. Please connect to CoonUp DAO network",
                  "Connecting Error"
                )
              )}
            </Content>

            <Footer>
              <FootNav
                netName={web3Instance.netName}
                onClickFootIcon={this.onClickFootIcon}
              />
            </Footer>
          </div>
        ) : (
          <div>
            <BaseLoader />
          </div>
        )}
      </Layout>
    );
  }
}

export default Home;
