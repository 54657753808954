import React from "react";
import { useCountdown } from "../utils/hooks/useCountdown";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? "countdown danger" : "countdown"}>
      <h1>{value}</h1>
      <h3 className="mb-0">{type}</h3>
    </div>
  );
};

const TimeEnd = () => {
  return <></>;
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <div className="inner">
        <DateTimeDisplay value={days} type={"Days"} isDanger={false} />
        <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
        <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
        <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  // if (days + hours + minutes + seconds <= 0) {
  //   return <TimeEnd />;
  // } else {
  return (
    <ShowCounter
      days={days > 0 ? days : 0}
      hours={hours > 0 ? hours : 0}
      minutes={minutes > 0 ? minutes : 0}
      seconds={seconds > 0 ? seconds : 0}
    />
  );
  // }
};

export default CountdownTimer;
