import Web3 from 'web3'
import VoteAbi from './utils/hooks/Voting.json'
import NFTcollectionABI from './utils/hooks/NFTAbi.json'

var web3Instance

let getWeb3Instance = () => {
  if (web3Instance) return web3Instance
const VotingAddress="0x83a786A60C1cD435d3F02B595dbA46e3128A5411"
  return new Promise((resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    window.addEventListener('load', async () => {
      // Checking if Web3 has been injected by the browser (Mist/MetaMask)
      let web3, netName, netId, branch, network, defaultAccount,haveToken,NFTCollectionAddress
      NFTCollectionAddress = "0x1432dA49866ba0d40BBf79B57318bcBdFF8eE084"
      if (window.ethereum) {
        web3 = new Web3(window.ethereum)
        try {
          await window.ethereum.enable()
        } catch (e) {
          reject(new Error('User denied account access'))
        }
      } else if (typeof window.web3 !== 'undefined') {
        web3 = new Web3(window.web3.currentProvider)
      } else {
        reject(new Error('User denied account access'))
      }

      if (web3) {
        netId = await web3.eth.net.getId()
        network = await web3.eth.net.getNetworkType()
        let errMsg = 'Unknown network. Please access to Ethereum mainnet'
   
        const accounts = await web3.eth.getAccounts()
        defaultAccount = accounts[0]

      } else {
        // Fallback to local if no web3 injection.
        web3 = new Web3(new Web3.providers.HttpProvider("https://eth-mainnet.g.alchemy.com/v2/uhYbRWneFwDpXPNHvAPLm1X_bO3H0TFE"))
      }
      const collectionContract = new web3.eth.Contract(NFTcollectionABI, NFTCollectionAddress);
      const walletToken = await collectionContract.methods.balanceOf(defaultAccount).call();
      if (walletToken > 0) {
        console.log('The wallet owns NFTs from the collection.');
        haveToken=true
      } else {
        console.log('The wallet does not own any NFTs from the collection.');
        haveToken=false

      }
      const contract="";
      // const contract = new web3.eth.Contract(VoteAbi, VotingAddress);
      web3Instance = {
        web3: web3,
        netName: netName,
        netId: netId,
        haveToken:haveToken,
        contract:contract,
        branch: branch,
        defaultAccount: defaultAccount,
        names: ['identity', 'ballotStorage', 'envStorage', 'governance', 'staking']
      }

      resolve(web3Instance)
    })
  })
}
export default getWeb3Instance
export { web3Instance }
